import ResourceService from '@/services/resource.service';
import axios from 'axios';
import { serialize } from '@/utils/url';

export default class TasksService extends ResourceService {
    /**
     * @returns {Promise<AxiosResponse<T>>}
     */
    getAll() {
        return axios.get('tasks/list');
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param id
     * @param filters
     */
    getItem(id, filters = {}) {
        return axios.post(this.getItemRequestUri(id), filters);
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param id
     */
    getItemRequestUri(id) {
        return `api/v1/tasks/${id}`;
    }

    /**
     * @param userID
     * @returns {Promise<AxiosResponse<T>>}
     */
    getDashboardTasks(userID) {
        return axios.get(`tasks / dashboard ? ${serialize({ user_id: userID, with: ['project'] })} `);
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param filters
     * @param config
     */
    getWithFilters(filters, config = {}) {
        return axios.post('api/v1/task/list', filters, config);
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param id
     */
    deleteItem(id) {
        return axios.delete(`api/v1/tasks/${id}?id=${id}`);
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param data
     * @param isNew
     */
    save(data, isNew = false) {
        return axios.post('api/v1/tasks', data);
    }

    update(data, id) {
        return axios.put(`api/v1/tasks/${id}`, data);
    }

    getOptionLabelKey() {
        return 'task_name';
    }

    async fetchTasks() {
        return await axios.post('api/v1/task/list', { disable_pagy: true });
    }
}
